<template>
    <main>
        <form>
            <div class="form-row">
                <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12">
                    <div class="card shadow-none border-0">
                        <div class="card-header bg-light">Examen Físico</div>
                        <div class="card-body">
                            <div class="form-group row">
                                <label class="col-sm-6 col-form-label"><i class="fa fa-stethoscope"></i> P.A.SISTOL /
                                    P.A.DIASTOL <span class="text-danger">(mmHg)</span> </label>
                                <div class="col-sm-6 ">
                                    <div class="row">
                                        <div class="col-sm ">
                                            <input type="number" v-model="pa_sistolica" class="form-control"
                                                :class="{ 'is-invalid': $v.pa_sistolica.$error && $v.pa_sistolica.$dirty }">
                                        </div>
                                        <div class="col-sm-1 ">
                                            <h1>/</h1>
                                        </div>
                                        <div class="col-sm ">
                                            <input type="number" v-model="pa_diastolica" class="form-control"
                                                :class="{ 'is-invalid': $v.pa_diastolica.$error && $v.pa_diastolica.$dirty }">
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="form-group row">
                                <label class="col-sm-6 col-form-label"><i class="fa fa-history"></i> PULSO <span
                                        class="text-danger">(lat/min)</span></label>
                                <div class="col-sm-6">
                                    <input type="number" v-model="pulso" class="form-control"
                                        :class="{ 'is-invalid': $v.pulso.$error && $v.pulso.$dirty }">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-6 col-form-label"><i class="fa fa-thermometer"></i> TEMPERATURA <span
                                        class="text-danger">(ºC)</span></label>
                                <div class="col-sm-6">
                                    <input type="number" v-model="temperatura" class="form-control"
                                        :class="{ 'is-invalid': $v.temperatura.$error && $v.temperatura.$dirty }">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-6 col-form-label"><i class="fa fa-lungs"></i> RESPIRACION <span
                                        class="text-danger">(r/m)</span></label>
                                <div class="col-sm-6">
                                    <input type="number" v-model="respiracion" class="form-control"
                                        :class="{ 'is-invalid': $v.respiracion.$error && $v.respiracion.$dirty }">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-6 col-form-label"><i class="fa fa-weight"></i> PESO <span
                                        class="text-danger">(KG)</span></label>
                                <div class="col-sm-6">
                                    <input type="number" v-model="peso" class="form-control"
                                        :class="{ 'is-invalid': $v.peso.$error && $v.peso.$dirty }">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-6 col-form-label"><i class="fa fa-ruler-vertical"></i> TALLA <span
                                        class="text-danger">(CM)</span></label>
                                <div class="col-sm-6">
                                    <input type="number" v-model="talla" class="form-control"
                                        :class="{ 'is-invalid': $v.talla.$error && $v.talla.$dirty }">
                                </div>
                            </div>
                            <div class="form-group row" v-if="modo === 'URGENCIA'">
                                <label class="col-sm-6 col-form-label"><i class="fa fa-percentage"></i> SATURACIÓN
                                    OXIGENO</label>
                                <div class="col-sm-6">
                                    <input type="number" class="form-control" v-model="saturacion_oxigeno">
                                </div>
                            </div>
                            <div class="form-group row" v-if="modo === 'INFANCIA'">
                                <label class="col-sm-6 col-form-label"><i class="fa fa-calculator"></i> PERIMETRO
                                    CEFALICO</label>
                                <div class="col-sm-6">
                                    <input type="number" class="form-control" v-model="perimetro_cefalico">
                                </div>
                                <label class="col-sm-6 col-form-label"><i class="fa fa-calculator"></i> PERIMETRO
                                    TORÁCICO</label>
                                <div class="col-sm-6">
                                    <input type="number" class="form-control" v-model="perimetro_toracico">
                                </div>
                            </div>
                            <div class="form-group row" v-if="modo === 'INFANCIA' || modo === 'VEJEZ'">
                                <label class="col-sm-6 col-form-label"><i class="fa fa-calculator"></i> PERIMETRO
                                    BRAQUEAL</label>
                                <div class="col-sm-6">
                                    <input type="number" class="form-control" v-model="perimetro_braqueal">
                                </div>
                            </div>
                            <div class="form-group row"
                                v-if="modo === 'JUVENTUD' || modo === 'ADULTO' || modo === 'VEJEZ' || modo === 'CRV'">
                                <label class="col-sm-6 col-form-label"><i class="fa fa-calculator"></i> PERIMETRO
                                    ABDOMINAL</label>
                                <div class="col-sm-6">
                                    <input type="number" class="form-control" v-model="perimetro_abdominal">
                                </div>
                            </div>
                            <div class="form-group row" v-if="modo === 'VEJEZ'">
                                <label class="col-sm-6 col-form-label"><i class="fa fa-calculator"></i> CIRCUNFERENCIA
                                    PANTORRILLA (CM)</label>
                                <div class="col-sm-6">
                                    <input type="number" class="form-control" v-model="circunferencia_pantorrilla">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-6 col-form-label"><i class="fa fa-calculator"></i> IMC</label>
                                <div class="col-sm-6">
                                    <input type="number" class="form-control" v-model="indice_masa_corporal" readonly>
                                </div>
                            </div>
                            <examenFisicoEmbarazo v-if="modo === 'EMBARAZO'" ref="examenFisicoEmbarazo"
                                :idHistoria="idHistoria" :finalizada="finalizada"></examenFisicoEmbarazo>
                        </div>
                    </div>
                    <exploracionFisica ref="exploracionFisica" :idHistoria="idHistoria" :finalizada="finalizada">
                    </exploracionFisica>
                </div>
                <div class="col-lg-4 mt-2" v-if="alerts.length">
                    <div class="card shadow-none">
                        <div class="card-body" role="alert">
                            <div :class="alert.class" v-for="(alert, i) in alerts" :key="`${i}_alarm`">
                                <div class="alert-icon-aside">
                                    <i class="fas fa-exclamation-triangle fa-fw"></i>
                                </div>
                                <div class="alert-icon-content py-3">
                                    {{ alert.text }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </main>
</template>
  
<script>

import { required, minValue, maxValue } from "vuelidate/lib/validators";

import examenFisicoService from "../../../../services/examenFisicoService";
import Toast from '../../../../components/common/utilities/toast';
import { isEmpty } from 'lodash';
import examenFisicoEmbarazo from "./examenFisicoEmbarazo";
import exploracionFisica from "./exploracionFisica";
import examenFisicoEmbarazoService from '../../../../services/examenFisicoEmbarazoService';
import datosExamenFisico from "../../../../assets/datasheets/examenFisico/alertasExamenFisico";

export default {
    props: ['idHistoria', 'finalizada', 'modo', 'idTipoHistoria', 'codigo'],
    components: { examenFisicoEmbarazo, exploracionFisica },
    data() {
        return {
            id_historia: '',
            id_examen_fisico: '',
            peso: '',
            talla: '',
            pa_sistolica: '',
            pa_diastolica: '',
            respiracion: '',
            pulso: '',
            temperatura: '',
            perimetro_abdominal: '',
            circunferencia_pantorrilla: '',
            perimetro_cefalico: '',
            perimetro_braqueal: '',
            imc: '',
            examen_fisico_embarazo: '',
            exploracion_fisica: '',
            saturacion_oxigeno: '',
            perimetro_toracico: '',
            examenFisico: {},
            examenFisicoEmbarazo: {},
        }
    },
    validations() {
        return {
            ...(this.modo === 'EMBARAZO' ? { examen_fisico_embarazo: { required } } : {}),
            exploracion_fisica: { required },
            peso: {
                minValue: minValue(0),
                maxValue: maxValue(999),
                required
            },
            talla: {
                minValue: minValue(0),
                maxValue: maxValue(999),
                required
            },
            pa_sistolica: {
                minValue: minValue(0),
                maxValue: maxValue(999),
                required
            },
            pa_diastolica: {
                minValue: minValue(0),
                maxValue: maxValue(999),
                required
            },
            respiracion: {
                minValue: minValue(0),
                maxValue: maxValue(99),
                required
            },
            pulso: {
                minValue: minValue(0),
                maxValue: maxValue(999),
                required
            },
            temperatura: {
                minValue: minValue(0),
                maxValue: maxValue(99),
                required
            },
        }
    },
    methods: {
        async save() {
            try {
                let examen = {};

                if (this.modo === 'EMBARAZO') {
                    const examen_embarazo = this.$refs.examenFisicoEmbarazo.retornar();
                    if (examen_embarazo) {
                        examen['examen_fisico_embarazo'] = examen_embarazo;
                        this.examen_fisico_embarazo = 'SI';
                    }
                }

                const exploracion_fisica = this.$refs.exploracionFisica.retornar();

                if (exploracion_fisica) {
                    this.exploracion_fisica = 'SI';
                    examen['partes_fisicas'] = exploracion_fisica;
                }

                this.$v.$touch();

                if (this.$v.$invalid) return;

                this.examenFisico.id_historia = this.idHistoria;
                this.examenFisico.peso = this.peso;
                this.examenFisico.talla = this.talla;
                this.examenFisico.pa_sistolica = this.pa_sistolica;
                this.examenFisico.pa_diastolica = this.pa_diastolica;
                this.examenFisico.pulso = this.pulso;
                this.examenFisico.temperatura = this.temperatura;
                this.examenFisico.respiracion = this.respiracion;
                this.examenFisico.perimetro_abdominal = this.perimetro_abdominal;
                this.examenFisico.perimetro_cefalico = this.perimetro_cefalico;
                this.examenFisico.perimetro_braqueal = this.perimetro_braqueal;
                this.examenFisico.circunferencia_pantorrilla = this.circunferencia_pantorrilla;
                this.examenFisico.saturacion_oxigeno = this.saturacion_oxigeno;
                this.examenFisico.perimetro_toracico = this.perimetro_toracico;

                examen = {
                    ...examen,
                    ...this.examenFisico
                };

                this.LoaderSpinnerShow();

                if (this.id_examen_fisico !== '') {
                    examen['id'] = this.id_examen_fisico;
                    await examenFisicoService.update(examen);
                } else {
                    await examenFisicoService.store(examen);
                    const response = await examenFisicoService.showByHc(this.idHistoria);
                    const responseEmbarazo = await examenFisicoEmbarazoService.showByHc(this.idHistoria);
                    this.id_examen_fisico = response.data.id;
                    this.id_examen_fisico_embarazo = responseEmbarazo.data.id;
                }

                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }

        },
    },
    watch: {
        finalizada: async function (value) {

            if (value === 'N') {
                const response = await examenFisicoService.showByHc(this.idHistoria);
                if (!isEmpty(response.data)) {
                    this.pulso = response.data.pulso;
                    this.pa_diastolica = response.data.pa_diastolica;
                    this.pa_sistolica = response.data.pa_sistolica;
                    this.talla = response.data.talla;
                    this.peso = response.data.peso;
                    this.temperatura = response.data.temperatura;
                    this.respiracion = response.data.respiracion;
                    this.id_examen_fisico = response.data.id;
                    this.perimetro_abdominal = response.data.perimetro_abdominal;
                    this.perimetro_cefalico = response.data.perimetro_cefalico;
                    this.perimetro_braqueal = response.data.perimetro_braqueal;
                    this.circunferencia_pantorrilla = response.data.circunferencia_pantorrilla;
                    this.saturacion_oxigeno = response.data.saturacion_oxigeno;
                    this.perimetro_toracico = response.data.perimetro_toracico;
                }
            }
        },
    },
    async created() {
        if (this.finalizada === 'N') {
            const response = await examenFisicoService.showByHc(this.idHistoria);
            if (!isEmpty(response.data)) {
                this.pulso = response.data.pulso;
                this.pa_diastolica = response.data.pa_diastolica;
                this.pa_sistolica = response.data.pa_sistolica;
                this.talla = response.data.talla;
                this.peso = response.data.peso;
                this.temperatura = response.data.temperatura;
                this.respiracion = response.data.respiracion;
                this.id_examen_fisico = response.data.id;
                this.perimetro_abdominal = response.data.perimetro_abdominal;
                this.perimetro_cefalico = response.data.perimetro_cefalico;
                this.perimetro_braqueal = response.data.perimetro_braqueal;
                this.circunferencia_pantorrilla = response.data.circunferencia_pantorrilla;
                this.saturacion_oxigeno = response.data.saturacion_oxigeno;
                this.perimetro_toracico = response.data.perimetro_toracico;
            }
        }
    },
    computed: {
        indice_masa_corporal() {

            if (this.peso !== '' && this.talla !== '') {
                let imc = Number(this.peso) / (Math.pow(Number(this.talla / 100), 2));
                return imc.toFixed(2);
            }

            return '';
        },
        referencias: function () {
            if (this.codigo && datosExamenFisico[this.codigo]) {
                return datosExamenFisico[this.codigo];
            } else {
                return null;
            }
        },
        alerts() {
            let alerts = [];
            if (this.referencias !== null) {


                if (this.pulso < this.referencias['PULSO'].min && this.pulso !== '') {
                    alerts.push(this.referencias['PULSO'].alert.min);
                }

                if (this.pulso > this.referencias['PULSO'].max && this.pulso !== '') {
                    alerts.push(this.referencias['PULSO'].alert.max);
                }

                if (this.pa_sistolica < this.referencias['TA_SISTOLICA'].min && this.pa_sistolica !== '') {
                    alerts.push(this.referencias['TA_SISTOLICA'].alert.min);
                }

                if (this.pa_sistolica > this.referencias['TA_SISTOLICA'].max && this.pa_sistolica !== '') {
                    alerts.push(this.referencias['TA_SISTOLICA'].alert.max);
                }

                if (this.pa_diastolica < this.referencias['TA_DIASTOLICA'].min && this.pa_diastolica !== '') {
                    alerts.push(this.referencias['TA_DIASTOLICA'].alert.min);
                }

                if (this.pa_diastolica > this.referencias['TA_DIASTOLICA'].max && this.pa_diastolica !== '') {
                    alerts.push(this.referencias['TA_DIASTOLICA'].alert.max);
                }

                if (this.temperatura < this.referencias['TEMPERATURA'].min && this.temperatura !== '') {
                    alerts.push(this.referencias['TEMPERATURA'].alert.min);
                }

                if (this.temperatura > this.referencias['TEMPERATURA'].max && this.temperatura !== '') {
                    alerts.push(this.referencias['TEMPERATURA'].alert.max);
                }

                if (this.respiracion < this.referencias['RESPIRACION'].min && this.respiracion !== '') {
                    alerts.push(this.referencias['RESPIRACION'].alert.min);
                }

                if (this.respiracion > this.referencias['RESPIRACION'].max && this.respiracion !== '') {
                    alerts.push(this.referencias['RESPIRACION'].alert.max);
                }

                let imcCategoria = "";
                if (this.indice_masa_corporal) {
                    if (this.indice_masa_corporal < 20) {
                        imcCategoria = "BAJO_PESO";
                    } else if (this.indice_masa_corporal >= 20 && this.indice_masa_corporal <= 24) {
                        imcCategoria = "NORMAL";
                    } else if (this.indice_masa_corporal >= 25 && this.indice_masa_corporal <= 29) {
                        imcCategoria = "SOBREPESO";
                    } else {
                        imcCategoria = "OBESIDAD";
                    }

                    const alertaIMC = this.referencias.IMC[imcCategoria].alert;
                    const classIMC = this.referencias.IMC[imcCategoria].class;
                    alerts.push({
                        text: alertaIMC,
                        class: classIMC
                    });
                }
            }
            return alerts;
        }
    }
}
</script>


