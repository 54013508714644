<template>
  <div class="card shadow-none border-0">
    <div class="card-header bg-light "><i class="fa fa-list fa-fw"> </i>Recomendaciones</div>
    <div class="card-body" v-if="false">
      <div class="row no-gutters border-bottom mb-1" v-for="item in recomendaciones" :key="item.id" >
        <div class="col-12 py-2">
          <blockquote class="small m-0 mr-4">{{item.recomendacion.recomendacion}}</blockquote>
        </div>
      </div>
    </div>
    <div class="card-body" >{{this.recomendacion}}</div>
  </div>
</template>

<script>
import recomendacionHistoriaService from "@/services/recomendacionHistoriaService";
import {isEmpty} from "lodash";

export default {
  props : ['idHistoria'],
  name: "recomendacionesShow",
  data(){
    return {
      recomendaciones : [],
      recomendacion:''
    }
  },
  async created() {
    const response = await recomendacionHistoriaService.index({
      id_historia : this.idHistoria
    });
    this.recomendaciones = response.data;

     const res = await recomendacionHistoriaService.showByHc(this.idHistoria);
      if(!isEmpty(res.data)){
        this.recomendacion=res.data.recomendacion 
      }
  }
}
</script>

<style scoped>

</style>