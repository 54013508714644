<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-diagnoses"> </i> Exploracion Fisica</span> </div>
            <div class="card-body">
                <div class="form-row">
                    <div class="form-group col-md-4" v-for="item in partes_fisicas" :key="item.id">
                        <h6> {{ item.nombre }} : {{ item.detalle }} </h6>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
//import partesFisicasService from "../../../../services/partesFisicasService";
import historiaExploracionFisicaService from "../../../../services/historiaExploracionFisicaService";
import { isEmpty } from 'lodash';
export default {
    props: ['idHistoria', 'finalizada'],
    data() {
        return {
            partes_fisicas: [],
        }
    },
    async created() {
        const response = await historiaExploracionFisicaService.showByHc(this.idHistoria);
        if (!isEmpty(response.data)) {
            response.data.forEach((x) => {
                let datos = {
                    detalle: x.detalle,
                    nombre: x.parte_fisica.nombre
                }
                this.partes_fisicas.push(datos);
            });
        }

    }
}
</script>