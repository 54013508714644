<template>
    <main>
        <div class="card-header bg-light "><span><i class="fa fa-list"> </i> Examen Mental</span> </div>
            <div class="card-body">
            <div class="row mt-2">
                <div class="list-group mb-3 col-lg-4 col-md-12 col-sm-12" v-for="(item) in examen_mental" :key="item.id">
                    <div class="list-group-item">
                        <div class=" ">
                            <span class="font-weight-bolder">{{ item.examen_mental.descripcion }}</span>
                            <p>{{item.valor}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import examenMentalUsuarioService from '../../../../services/examenMentalUsuarioService';
export default {
    props: ['embarazo_actual', 'usuario', 'idHistoria', 'finalizada', 'modo'],
    data() {
        return {
            examen_mental: []
        }
    },
    async created() {
        const response = await examenMentalUsuarioService.index(
            this.idHistoria
        ); 

        this.examen_mental = response.data;
    }

}
</script>