<template>
    <main>
        <div class="alert alert-warning mt-2" v-if="!remisiones.length">
            No existen remisiones...
        </div>
        <div v-else class="mt-2" v-for="item in remisiones" :key="item.id">
            <div class="card-header bg-light">
                <div class="row">
                    <div class="col-4 ">
                        Remisión No. {{item.id}} 
                    </div>
                    <div class="col-8 d-flex justify-content-end">
                        {{item.user.name}} ({{item.created_at | formatDateTime}})
                        <button v-if="item.id_user===user.id" @click="eliminar(item.id);" class="btn btn-outline-danger lift mx-2 shadow-sm btn-sm" >
                            <i class="fa fa-trash-alt"></i>
                        </button>
                        <button @click="imprimir(item.id);" class="btn btn-outline-info lift mx-2 shadow-sm btn-sm" >
                            <i class="fa fa-print fa-fw"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body" >
                <div class="row">
                    <div class="col-md-4 col-sm-12 col-lg-4">
                        Servicio <span class="text-danger">({{item.servicio.codigo}}) :</span>
                    </div>
                    <div class="col-md-8 col-sm-12 col-lg-8">
                        <span class="text-primary">{{item.servicio.descripcion}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-sm-12 col-lg-4">
                        Institución: 
                    </div>
                    <div class="col-md-8 col-sm-12 col-lg-8">
                        <span class="text-bold">{{item.institucion}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-sm-12 col-lg-4">
                        Motivo:
                    </div>
                    <div class="col-md-8 col-sm-12 col-lg-8">
                        <p class="text-black-50">{{item.motivo}}</p>
                    </div>
                </div> 
            </div>
        </div>
    </main>
</template>
<script>

import Toast from '../../../../components/common/utilities/toast';
import remisionService from '../../../../services/remisionService';
import Swal from 'sweetalert2';
export default {
    props: ['idHistoria'],
    data(){
        return {
            remisiones:{}
        }
    },
    methods: {
        async cargarRemisiones(id){
            const response = await remisionService.showRemisionByHc(id);
            this.remisiones= response.data;
        },
        imprimir(id_remision){
            window.open(remisionService.print(this.idHistoria,id_remision));
        },
        async eliminar(id) {
            const result = await Swal.fire({
                title :  "¿Está seguro de eliminar esta remisión?",
                text : "No se podrá revertir los cambios",
                showCancelButton : true,
                showConfirmButton : true,
                confirmButtonText : 'Sí',
                cancelButtonText : 'No',
                icon : "warning",
                showLoaderOnConfirm : true,
                preConfirm: async () => {
                    try{
                        await remisionService.delete(id);
                    }catch (e) {
                        console.error(e);
                        Swal.showValidationMessage('ha ocurrido un error al procesar la solicitud');
                    }
                },
            });

            if(result.isConfirmed){
                Toast.fire({
                    icon: 'success',
                    title: 'Datos eliminados con exito'
                });
                this.cargarRemisiones(this.idHistoria);
            }            
        },
    },
    async created(){
        try {
            await this.cargarRemisiones(this.idHistoria);
        } catch (error) {
            console.error(error);
            Toast.fire({
                icon: 'error',
                title: 'Ocurrio un error al procesar la solicitud'
            });
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    }
}
</script>