<template>

    <main ref="resumen" id="resumen">
        <div class="py-2"><motivoConsultaShow :idHistoria="idHistoria"></motivoConsultaShow></div>
        <div class="py-2"><examenFisicoShow :idHistoria="idHistoria"></examenFisicoShow></div>
        <div class="py-2"><exploracionFisicaShow :idHistoria="idHistoria"></exploracionFisicaShow></div>
        <div class="py-2"><showExamenMental :idHistoria="idHistoria"></showExamenMental></div>
        <!-- <div class="py-2"><revision-sistemas-show :id-historia="idHistoria"></revision-sistemas-show></div> -->
        <!-- <div class="py-2"><ordenClinicaShow :idHistoria="idHistoria"></ordenClinicaShow></div>
        <div class="py-2"><formulaMedicaShow :idHistoria="idHistoria"></formulaMedicaShow></div> -->
        <div class="py-2"><impresionDxShow :idHistoria="idHistoria"></impresionDxShow></div>
        <div class="py-2"><intervenciones-show :id-historia="idHistoria"></intervenciones-show></div>
        <div class="py-2"><recomendaciones-show :id-historia="idHistoria"></recomendaciones-show></div>
    </main>

</template>
<script>
import motivoConsultaShow from "../base/motivoConsulta/motivoConsultaShow";
import examenFisicoShow from "../base/examenFisico/examenFisicoShow";
//import ordenClinicaShow from "../base/orden/ordenClinicaShow";
//import formulaMedicaShow from "../base/formulaMedica/formulaMedicaShow";
import impresionDxShow from "../base/impresionDX/impresionDxShow";
import exploracionFisicaShow from "../base/examenFisico/exploracionFisicaShow";
import IntervencionesShow from "@/views/historias/base/intervenciones/intervencionesShow";
import RecomendacionesShow from "@/views/historias/base/recomendaciones/recomendacionesShow";
//import RevisionSistemasShow from "@/views/historias/base/baseUrgencia/revisionShow";
import showExamenMental from "../base/baseExamenMental/show";

export default {
    props: ['idHistoria','idUsuario'],
    components:{motivoConsultaShow,
    examenFisicoShow,
    impresionDxShow,
    exploracionFisicaShow,IntervencionesShow,RecomendacionesShow,showExamenMental},
    data () {
        return {
            imgData : '',
        }
    }
}
</script>
<style scoped>
.resumen-container {
    height:300px;
    overflow-y:scroll
}

</style>