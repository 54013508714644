<template>
    <input v-model="val"  type="number" class="form-control">
</template>
<script>
export default {
    props: ['antecedente','value'],
    data () {
        return{
            val:this.value,
        }
    },
    watch:{
        val:function(){
            this.$emit('input',this.val);
        },
        value: function (newValue) {
            if (newValue !== this.val) {
                this.val = newValue;
            }
        },
    }
}
</script>


