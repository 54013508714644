<template>
    <div class="modal fade" id="modalFormula" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Formulas Medicas</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="alert alert-warning mt-2" v-if="!formulas.length">
                        No se han cargado formulas...
                    </div>
                    <div v-else class="card mt-2 border-gray-500 shadow-none" v-for="item in formulas" :key="item.id">
                        <div class="card-header bg-light">
                            <div class="row">
                                <div class="col-4 ">
                                    Fórmula No. {{item.id}} 
                                </div>
                                <div class="col-8 d-flex justify-content-end">
                                    <span class="text-danger mx-2">{{item.user.name}}</span> ({{item.created_at | formatDateTime}})
                                </div>
                            </div>
                        </div>
                        <div class="card-body" >
                            <div class="row">
                                <div class="col-md-8 col-sm-12 col-lg-12 font-weight-bold" v-for="formula in item.items" :key="formula.id">
                                    {{ formula.servicio.codigo }}
                                    {{ formula.servicio.descripcion }}
                                    <div class="small text-muted d-none d-md-block">
                                        Indicaciones: {{ formula.indications }}
                                    </div>
                                    <hr>
                                </div>
                                <div class="col-md-8 col-sm-12 col-lg-12">
                                    Observaciones Generales: <span class="text-primary">{{item.observaciones}}</span>
                                </div>
                            </div>
                            <div class="btn-group shadow-sm float-right" role="group" >
                                <button @click="verFormula(item.id)" class="btn btn-outline-primary btn-xs" >
                                    <i class="fa fa-eye fa-fw"></i> Ver fórmula
                                </button>
                                <button @click="imprimir(item.id)" class="btn btn-outline-success btn-xs" >
                                    <i class="fa fa-print fa-fw"></i> Imprimir
                                </button>
                                <button v-if="item.id_user===user.id" @click="eliminarFormula(item.id)" class="btn btn-outline-danger btn-xs" >
                                    <i class="fa fa-trash-alt"></i> Eliminar 
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                </div>
                </div>
            </div>
        </div>
</template>
<script>
import formulaMedicaService from "../../../../services/formulaMedicaService";
import $ from 'jquery';
import Toast from '../../../../components/common/utilities/toast';
import historiaClinicaService from "../../../../services/historiaClinicaService";
import Swal from 'sweetalert2';
export default {
    props: ['formulas','usuario'],
    methods:{
        async eliminarFormula(id) {
            const result = await Swal.fire({
                title :  "¿Quiere eliminar esta fórmula?",
                text : "Esta opción no es reversible",
                showCancelButton : true,
                showConfirmButton : true,
                confirmButtonText : 'Sí, Eliminar',
                cancelButtonText : 'No',
                icon : "question",
                showLoaderOnConfirm : true,
                preConfirm: async () => {
                    try{
                        this.LoaderSpinnerShow();
                        await formulaMedicaService.delete(id);
                        this.LoaderSpinnerHide();
                    }catch (e) {
                        console.error(e);
                        this.LoaderSpinnerHide();
                        Swal.showValidationMessage('ha ocurrido un error al procesar la solicitud');
                    }
                },
            });

            if(result.isConfirmed){
                Toast.fire({
                    icon: 'success',
                    title: 'Datos eliminados con exito'
                });
                $('#modalFormula').modal('hide');
            } 
        },
        async verFormula(id) {
            $('#modalFormula').modal('hide');
            this.$router.push({name:'formula.clinica',params:{'id_formula':id,'id_usuario':this.usuario.id}});
        },
        imprimir(id_formula){
            window.open(historiaClinicaService.formula(id_formula));
        },
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    }
}
</script>

