<template>
    <main>
        <div class="form-group mt-4">
            <label  class="form-label"><i class="fas fa-poll-h fa-fw"></i>Servicio al que se remite</label>
            <v-select-servicio v-model="remision.servicio" mode="object" 
             :validator="$v.remision.servicio"
             :class="{
                'is-invalid' : $v.remision.servicio.$error && $v.remision.servicio.$dirty
             }"  ></v-select-servicio>
             <span class="invalid-feedback" v-if="!$v.remision.servicio.required">
                Este campo es requerido
            </span>
        </div>
        
        <div class="form-group">
            <label class="small mb-1">Administradora (EPS) <small>(*)</small></label>
            <v-select-eps mode="object" v-model="eps"
            :class="{
                'is-invalid' : $v.eps.$error && $v.eps.$dirty
             }"></v-select-eps>
            <span class="invalid-feedback" v-if="!$v.eps.required">
                Este campo es requerido
            </span>
        </div>
    
        <div class="form-group">
            <label  class="form-label"><i class="far fa-comment-alt fa-fw"></i> Motivo </label>
            <div class="">
                <textarea  v-model="remision.motivo" class="form-control" :class="{'is-invalid':$v.remision.motivo.$error && $v.remision.motivo.$dirty}"></textarea>
            </div>
        </div>   

        <button class="btn btn-success btn-sm" @click="guardar()">
            <i class="fas fa-save"></i>&nbsp;Guardar
        </button>
    </main>
</template>
<script>
import vSelectServicio from "../../../../components/common/VSelectServicio";
import remisionService from '../../../../services/remisionService';
import {required} from "vuelidate/lib/validators";
import Toast from '../../../../components/common/utilities/toast';
import VSelectEps from "../../../../components/common/VSelectEps";

export default {
    props:['idHistoria','usuario'],
    components: {vSelectServicio,VSelectEps},
    data() {
        return{
            remision:{
                id_historia:'',
                institucion:'',
                motivo:'',
                servicio:{},
            },
            eps:{}
        }
    },
    validations (){
        return {
            remision:{ 
                servicio:{required},
                motivo:{required},
            },
            eps:{required}
        }
    },
    methods: {
        async guardar() {
            try {
                this.$v.$touch();
                if(this.$v.$invalid){
                    return
                }
               
                this.LoaderSpinnerShow();
                this.remision.id_historia=this.idHistoria;
                this.remision.institucion=this.eps.label;
                await remisionService.store(this.remision);
                this.limpiarCampos();
                this.LoaderSpinnerHide();
                
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
                this.$emit('cargar-Remisiones');
            }catch (error) {
                console.error(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        limpiarCampos(){
            this.remision={};
        },
    },
    created(){
        this.eps = {
            id : this.usuario.contratos[0].contrato.eps.IDEPS,
            label : this.usuario.contratos[0].contrato.eps.DESEPS,
            ...this.usuario.contratos[0].contrato.eps
        }
    },
    watch:{
        usuario : function(){
            this.eps = {
                id : this.usuario.contratos[0].contrato.eps.IDEPS,
                label : this.usuario.contratos[0].contrato.eps.DESEPS,
                ...this.usuario.contratos[0].contrato.eps
            }
        }
    }
}
</script>
