<template>
  <main>
    <modalDiagnosticoEgreso :idHistoria="idHistoria" :idUsuario="idUsuario" :codigoHistoria="codigoHistoria" ></modalDiagnosticoEgreso>
    <button class="btn btn-success btn-sm mx-2" @click="codigoHistoria==='URG'?modalEgreso():finalizar();">
      <i class="fas fa-flag-checkered fa-fw"></i>
      Finalizar Historia
    </button>
  </main>
</template>

<script>
import $ from "jquery";
import Swal from "sweetalert2";
import historiaClinicaService from "@/services/historiaClinicaService";
import modalDiagnosticoEgreso from "./modalDiagnosticoEgreso";
export default {
  props : ['idHistoria', 'idUsuario','codigoHistoria'],
  name: "finalizarHistoria",
  components:{modalDiagnosticoEgreso},
  methods : {
    async finalizar(){

      const result = await Swal.fire({
        title :  "¿Está seguro de finalizar la historia ?",
        text : "Al finalizar la historia no se podran realizar cambios",
        showCancelButton : true,
        showConfirmButton : true,
        confirmButtonText : 'Sí, finalizar',
        cancelButtonText : 'No',
        icon : "warning",
        showLoaderOnConfirm : true,
        preConfirm: async () => {
          try{
            await historiaClinicaService.close({
              id_historia : this.idHistoria
            });
          }catch (e) {
            console.error(e);
            Swal.showValidationMessage('ha ocurrido un error al procesar la solicitud');
          }

        },
      });

      if(result.isConfirmed){
        await Swal.fire('Exíto', 'Historia finalizada con exíto', 'success');
        this.$router.push({
          name : 'usuario.historial.historias',
          params : {
            id : this.idUsuario
          }
        });
      }
    },
    async modalEgreso() {
      $('#modalDiagnosticoEgreso').modal('show');
      //const response = await formulaMedicaService.show(this.idHistoria);
      //this.formulas = response.data; 
    },
  }
}
</script>

<style scoped>

</style>