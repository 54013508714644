<template>
    <div class="modal fade" id="modalDiagnosticoEgreso" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Diagnostico de Salida</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="form-group col-lg-6 col-md-12 col-sm-12">
                        <input type="date" class="form-control form-control-sm" v-model="fecha" :class="{'is-invalid':$v.fecha.$error && $v.fecha.$dirty}">
                    </div>
                    <div class="form-group col-lg-6 col-md-12 col-sm-12">
                        <input type="time" class="form-control form-control-sm" v-model="hora" :class="{'is-invalid':$v.hora.$error && $v.hora.$dirty}">
                    </div>
                    <div class="form-group col-lg-10 col-md-12 col-sm-12"> 
                        <v-select-dx v-model="diagnostico" mode="object" 
                        :class="{'is-invalid' : $v.diagnostico.$error}"></v-select-dx>
                        <span v-if="!$v.diagnostico.required && $v.diagnostico.$dirty" class="text-danger">
                            <i class="fas fa-exclamation-triangle"></i> Este campo es requerido.
                        </span>
                    </div>
                    <div class="form-group col-lg-2 col-md-12 col-sm-12">      
                        <button class="btn btn-primary btn-sm mt-1" @click="agregarDiagnostico()">
                            <i class="fas fa-plus"></i>&nbsp;Agregar
                        </button>
                    </div>
                    <div class="table table-responsive mt-2 col-lg-12 col-md-12 col-sm-12">
                        <table  class="table table-borderless mb-0 table-sm">
                            <thead class="border-bottom bg-light">
                                <tr class="small text-uppercase text-muted">
                                    <th scope="col">Codigo</th>
                                    <th scope="col">Diagnostico</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="border-bottom" v-for="(item,index) in items" :key="item.id" >
                                    <td>{{item.ID_DIAG}}</td>
                                    <td class="font-weight-bold">{{ item.DESC_DIAG }}</td>
                                    <td>
                                        <button class="btn btn-outline-danger btn-sm"  @click="removerServicio(index)">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </td>
                                </tr>    
                            </tbody>                            
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-success btn-sm" @click="altaMedica();"><i class="fa fa-save fa-fw"></i>Guardar</button>
                <button type="button" class="btn btn-default btn-sm" data-dismiss="modal"><i class="fa fa-times-circle fa-fw"></i>Cerrar</button>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import Toast from '../../../../components/common/utilities/toast';
import historiaClinicaService from "../../../../services/historiaClinicaService";
import Swal from 'sweetalert2';
import vSelectDx from "../../../../components/common/VSelectDx";
import {required } from "vuelidate/lib/validators";
import {isEmpty} from 'lodash';
import dayjs from 'dayjs';
export default {
    props: ['idHistoria', 'idUsuario','codigoHistoria'],
    components:{vSelectDx},
    data(){
        return {
            fecha:'',
            hora:'',
            diagnostico:{},
            items: [],
        }
    },
    validations (){
        return {
            diagnostico:{required},
            fecha:{required},
            hora:{required},
        }
    },
    methods:{
        agregarDiagnostico() {
            this.$v.$touch();
            if(this.$v.$invalid) return;
            this.items.push({...this.diagnostico});
        },
        removerServicio(index) {
            this.items.splice(index, 1);
        },
        async altaMedica(){
            try {
                this.$v.fecha.$touch();
                if(this.$v.fecha.$invalid) return;

                this.$v.hora.$touch();
                if(this.$v.hora.$invalid) return;

                if (isEmpty(this.items)) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Debe agregar por lo menos un diagnostico'
                    });
                    return;
                }
                const result = await Swal.fire({
                    title :  "¿Está seguro de finalizar la historia ?",
                    text : "Al finalizar la historia no se podran realizar cambios",
                    showCancelButton : true,
                    showConfirmButton : true,
                    confirmButtonText : 'Sí, finalizar',
                    cancelButtonText : 'No',
                    icon : "warning",
                    showLoaderOnConfirm : true,
                    preConfirm: async () => {
                        try{
                            await historiaClinicaService.close({
                                id_historia : this.idHistoria,
                                fecha: this.fecha,
                                hora: this.hora,
                                diagnosticos_salida:this.items
                            });
                        }catch (e) {
                            console.error(e);
                            Swal.showValidationMessage('ha ocurrido un error al procesar la solicitud');
                        }
                    },
                });

                if(result.isConfirmed){
                    await Swal.fire('Exíto', 'Historia finalizada con exíto', 'success');
                    $('#modalDiagnosticoEgreso').modal('hide');
                    this.$router.push({
                        name : 'usuario.historial.historias',
                        params : {
                            id : this.idUsuario
                        }
                    });
                }
                
            } catch (error) {
                console.log(error);
                this.LoaderSpinnerHide();
            }
        }
    },
    created(){
        const date_now=dayjs();
        this.fecha = date_now.format("YYYY-MM-DD");
        this.hora = date_now.format("HH:mm");
    }
}
</script>

